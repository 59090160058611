<template>
	<div class="home">
		<div class="home-focus">
			<div class="container">
				<!--上半部分的右边-->
				<div class="home-focus-right clearfix">
					<!--大图部分-->
					<el-carousel height="420px" class="main-banner">
						<el-carousel-item v-for="(item,index) in bannerImgList" :key="index">
							<img :src="item.imgurl" alt="">
						</el-carousel-item>
					</el-carousel>

					<!-- 上半部分的最右边 -->
					<div class="other-focus help-list">
						<div class="au-customer">
							<div class="customer">
								<div class="tit pb10">
									<router-link to="" target="_blank">联系客服</router-link>
								</div>
								<div class="customer-list color-666">{{kefuNum}} </div>
								<div class="kef-box"><em id="">在线客服</em></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 今日标的 -->
		<div class="auction-today J-auction-today">
			<div class="container">
				<div class="main-auction">
					<router-link to="/list" target="_blank" class="main-tit clearfix">
						<h2 class="main-tit-title">
							今日标的
							<span class="more-count">更多</span>
						</h2>
					</router-link>
					<div class="auction-list-wrap today-list">
						<goodsList :commodityData="commodityData"></goodsList>
					</div>
				</div>
			</div>
		</div>

		
	</div>
</template>

<script>
	import goodsList from '../../components/goodsList.vue'
	import {
		getBanner,
		getCteSveInfo,
		getBiddingList
	} from '../../axios/api.js'
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		data() {
			return {
				bannerImgList: [],
				kefuNum: '',
				page: 1, //页码
				limit: 8, //显示条数,
				commodityData: '', //商品数据			
			}
		},
		components: {
			goodsList
		},
		created() {
			this.getBanner()
			this.getCteSveInfo()
			this.getBiddingList()
			
		},
		methods: {
			...mapMutations(['updateLogin']),
			// 轮播图
			getBanner() {
				let that = this
				getBanner().then(function(res) {
						console.log('getBanner:', res.data)
						let data = res.data
						if (data.code == 0) {
							that.bannerImgList = data.result
						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},
			// 获取客服信息
			getCteSveInfo() {
				let that = this
				getCteSveInfo().then(function(res) {
						console.log('getCteSveInfo:', res.data)
						let data = res.data
						if (data.code == 0) {
							that.kefuNum = data.result
						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},

			// 获取标的列表
			getBiddingList() {
				let that = this
				let data = {
					typeId: '', //标的类型
					province: '', //标的所在地
					city: '', //标的所在地
					entrust: '', //委托类型
					status: '', //标的状态
					page: this.page, //页码
					limit: this.limit, //显示条数
				}
				getBiddingList(data).then(function(res) {
						console.log('getBiddingList:', res.data)
						let data = res.data
						if (data.code == 0) {
							that.commodityData = data.result
						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('err:', err)
					})
			},

			

		}
	}
</script>

<style lang="less" scoped>
	.home-focus {
		margin: 18px 0;

		.J-banner-img,
		.list-box,
		.list-box-h3-s,
		.list-box-ul,
		.main-banner {
			position: relative;
		}

		.home-focus-right {
			float: left;

			.help-icon,
			.tit {
				height: 18px;
				text-align: center;
			}

			.main-banner {
				width: 910px;
				height: 420px;
				float: left;
				overflow: hidden;

				img {
					display: block;
					width: 100%;
					height: 100%;
				}
			}

			/deep/.el-carousel {
				.el-carousel__button {
					width: 10px;
					height: 10px;
					border-radius: 6px;
					opacity: 1
				}

				.el-carousel__indicator {
					&.is-active {
						button {
							background-color: #b83140
						}
					}
				}
			}

			.other-focus {
				width: 270px;
				margin-left: 20px;
				background: #fff;
				height: 420px;
				overflow: hidden;
				float: left;

				.au-customer {
					background: #f5f7f9;
					zoom: 1;

					.customer {
						padding: 17px 15px 0;
						height: 360px;
						background: #fff;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					}

					.customer-list {
						font-size: 20px;
						font-weight: 700;
						color: #B93720;
						line-height: 26px;
						text-align: center;
					}

					.kef-box {
						width: 158px;
						height: 37px;
						background: #F8F7F7;
						margin: 15px auto 0;

						em {
							background: url(../../assets/img/home/kefu.png) left 38px center no-repeat;
							display: block;
							width: 100%;
							height: 100%;
							background-size: 20px 20px;
							line-height: 37px;
							text-indent: 68px;
							font-size: 14px;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	// 今日标的
	.auction-today {

		.auction-list-wrap,
		.enterprises-list-wrap,
		.lots-list-wrap,
		.notice-contents {
			overflow: hidden;
		}

		.auction-list-wrap {
			padding-bottom: 50px;
		}

		.lots-list {
			margin-right: -20px;
		}
	}

	// 提示弹窗
	/deep/.el-dialog {
		width: 420px;

		.el-button--primary {
			color: #FFF;
			background-color: #f24b53;
			border-color: #f24b53;
		}

		.el-button--default {

			&:focus,
			&:hover {
				color: #f24b53;
				border-color: #f24b53;
				background-color: #fff;
			}
		}

		.el-button+.el-button {
			margin-left: 20px;
		}
	}
</style>
