<template>
	<ul class="lots-list J-today-list clearfix">
		<li class="lot-li" v-for="(item,index) in commodityData" :key="index">
			<div class="attention_box">
				<p class="attention_guanz attention_p" datalotid="588811">关注</p>
			</div>
			<router-link :to="{path:'/details',query:{id:item.id}}" class="lot-li-a">
				<div class="lot-header-section">
					<img :src="item.path" class="lot-pic" alt="">
				</div>
				<p class="lot-title">{{item.name}}</p>
				<div class="info-section">
					<div class="price-box">
						<p class="price price-current" 
							v-if="item.status == 1 ||item.status == 4 ||item.status == 5">
							<span class="label">起拍价</span>
							<span class="lot-current-price"
								:class="item.status == 4 ||item.status == 5?'textColor':'' ">
							{{item.startPrice}}<em class="f12">元</em></span>
						</p>
						<p class="price price-current" v-if="item.status == 2">
							<span class="label">当前价</span>
							<span class="lot-current-price">{{item.nowPrice}}<em class="f12">元</em></span>
						</p>
						<p class="price price-current" v-if="item.status == 3">
							<span class="label">成交价</span>
							<span class="lot-current-price">{{item.nowPrice}}<em class="f12">元</em></span>
						</p>
					</div>
					
					<p class="lot-time time-doing pai-status-doing-show">
						<span class="label">预计</span>
						<span class="value pai-countdown pai-xmpp-countdown">
							<span class="time-text">{{item.endTime}}<span>结束</span></span>
						</span>
					</p>
				</div>
				<div class="lot-state-div">
					<!-- 暂时隐藏 -->
					<!-- <p class="num-apply">
						<span class="pai-xmpp-viewer-count">2626</span>次围观
						<span class="bid-tips"><em class="pai-xmpp-bid-count">4</em>次出价</span>
					</p> -->
					<!-- 状态 -->
					<p class="num-auction">
						
						<span class="start" v-if="item.status == 1">{{item.status | goodsStatu}}</span>
						<span class="now" v-if="item.status == 2">{{item.status | goodsStatu}}</span>
						<span class="finish" 
							v-if="item.status == 3 || item.status == 4 || item.status == 5">
							{{item.status | goodsStatu}}
						</span>
					</p>
				</div>
				<div class="flag-section" style="display: none;">
					<div class="flag flag-done"></div>
					<p>已成交</p>
				</div>
				<div class="tag-section">
					<div class="pai-tag  tag-buy-restrictions" style="display: none;">不限购</div>
					<div class="pai-tag  tag-support-loans" style="display: none;">可贷款</div>
					<div class="pai-tag  tag-support-Insurance" style="display: none;">保险</div>
				</div>
			</router-link>
		</li>						
	</ul>
</template>

<script>
	export default{
		props: ['commodityData'],
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.lots-list {
	    margin-right: -20px;
		.ellipsis2, .ellipsis3, .ellipsis5, .lot-title {
		    -webkit-box-orient: vertical;
		    word-break: break-all;
		    overflow: hidden;
		}
		.lot-li {
		    float: left;
		    margin: 20px 20px 0 0;
		    background: #fff;
		    position: relative;
			&:hover{
				transform: scale(1.03);
				transition: all .3s ease;
				.attention_box{
					opacity: 1;
				}
			}
			.attention_box {
			    width: 84px;
			    position: absolute;
			    height: 30px;
			    right: 0;
			    top: 160px;
			    background: rgba(0,0,0,.4);
			    z-index: 99;
			    transition: all .3s ease;
			    border-top-left-radius: 20px;
			    opacity: 0;				
				.attention_p {
				    background: url(../assets/img/home/follow_whitechecked.png) left center no-repeat;
				    padding-left: 22px;
				    margin-left: 16px;
				}
				.attention_p, .attention_pac {
				    color: #fff;
				    height: 30px;
				    line-height: 30px;
				    font-size: 14px;
				}
			}
			.lot-header-section, .lot-li-a {
			    display: block;
			    position: relative;
			}
			.lot-header-section {
			    text-align: center;
			    overflow: hidden;
			    width: 100%;
			    height: 190px;
			    line-height: 190px;
			}
			.lot-li-a {
			    width: 285px;
			    transition: all .6s ease;
				&:hover {
					-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
					-moz-box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
					box-shadow: 0 5px 15px rgba(0, 0, 0, .2)
				}
				.lot-pic {
				    vertical-align: top;
				    width: 100%;
				    max-height: 100%;
				}
				.lot-title {
				    display: -webkit-box;
				    -webkit-line-clamp: 2;
				    color: #333;
				    font-size: 14px;
				    height: 40px;
				    line-height: 20px;
				    text-align: left;
				    padding: 0 10px;
				    margin: 10px 0;
				}
				.info-section {
				    color: #333;
				    display: block;
				    font-size: 12px;
				    padding-left: 10px;
				    padding-bottom: 6px;
				    text-align: left;
				}
				.price {
				    margin-bottom: 6px;
				    height: 21px;
				    line-height: 21px;
					.label {
					    vertical-align: 2px;
					}
				}
				.price-current .lot-current-price, .price-done .lot-current-price {
				    color: #d6363b;
				}
				.lot-current-price {
				    color: #333;
				    padding-left: 6px;
				    font-size: 18px;
				}
				.textColor{
					color: #333!important;
				}
				.lot-price-assess, .lot-time {
				    line-height: 20px;
				    height: 20px;
				}
				.value {
				    color: #333;
				    font-size: 14px;
				}
				.lot-state-div {
				    zoom: 1;
				    clear: none!important;
				    border-top: 1px solid #eee;
				    width: 100%;
				    height: 38px;
				    line-height: 38px;
					.num-apply, .num-auction {
					    color: #666;
					    float: right;
					    padding-left: 10px;
					    margin: 0;
						em{
							padding: 0 0 0 20px;
							overflow: hidden;
							text-align: right;
							-o-text-overflow: ellipsis;
							text-overflow: ellipsis;
							white-space: nowrap;
							word-break: keep-all;
							word-wrap: normal;
						}
					}
					.num-apply {
					    float: left;
					    font-size: 12px;
					}
					.num-auction {
					    padding-left: 0;
					    margin: 9px 10px 9px 0;
						span{
							display: block;
							height: 20px;
							font-size: 12px;
							padding: 0 10px;
							color: #fff;
							line-height: 20px;
							text-align: center;
							&.now{
								background: #d6363b;
							}
							&.finish {
							    background: #d7d7d7;
							}
							&.start {
							    background: #05a981;
							}
						}
					}
				}
				.flag-section {
				    position: absolute;
				    top: 0;
				    left: 0;
				    right: 0;
				    height: 190px;
				    display: none;
					.flag-done {
					    left: 0;
					    top: 0;
					    width: 100%;
					    height: 100%;
					    position: absolute;
					    background: #DBD6D3;
					    opacity: .6;
					    filter: alpha(opacity=60);
					}
					p {
					    width: 134px;
					    height: 54px;
					    border: 3px solid #f2f2f2;
					    color: #f2f2f2;
					    display: inline-block;
					    position: absolute;
					    left: 25%;
					    font-weight: 700;
					    top: 65px;
					    font-size: 16px;
					    text-align: center;
					    line-height: 54px;
					}
				}
				.tag-section {
				    position: absolute;
				    top: 0;
				    left: 0;
				    width: 100%;
				    zoom: 1;
				    clear: none!important;
					.tag-buy-restrictions {
					    background: linear-gradient(90deg,#E9975A,#E37930);
					    color: #fff;
					}
					.pai-tag {
					    position: relative;
					    float: right;
					    margin-right: 2px;
					    width: 45px;
					    height: 22px;
					    line-height: 22px;
					    text-align: center;
					    font-size: 12px;
					}
				}
			}
		}
	}
</style>
